import React, { useEffect, useState } from 'react';

import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import PageIndicator from '../../../components/PageIndicator/PageIndicator';
import BoxCoverPlayerDetailsComponent from '../../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';

import { clearSpecialCharactersReplacements, isWeb } from '../../../services/util-service/util.service';
import { routes } from '../../../services/navigation/navigation.service.routes';

import { navigationOptions } from '../../../constants/navigation';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';
import { useWLconfigContext } from '../../../context/WLConfigContext';

import './JustAddedPage.scss';

const JustAddedPage = (props) => {
  const { location = {} } = props;

  const {
    videos,
    pagination: { page, last_page },
    sort,
    type,
    loading,
    error,
    onPageChange,
    onTypeChange,
    onSortByClick,
  } = useMoviesScenesData({ location });

  const [state, setState] = useState({ spotlight: {} });
  const { spotlight = {} } = state;
  const { wl_config: {
    movie_spotlight_id,
    scene_spotlight_id,
    scene_spotlight_movie_id,
    max_just_added_page_count
  } = {} } = useWLconfigContext();

  useEffect(() => {
    const data = {};
    if (movie_spotlight_id) {
      data.movieSpotlight = {
        movieId: movie_spotlight_id,
      };
    }
    if (scene_spotlight_id) {
      data.sceneSpotlight = {
        movieId: scene_spotlight_movie_id,
        sceneId: scene_spotlight_id,
      };
    }

    const spotlight = type === navigationOptions.scenes ? data.sceneSpotlight : data.movieSpotlight;
    setState((prevState) => ({ ...prevState, spotlight }));
  }, [type, movie_spotlight_id, scene_spotlight_id, scene_spotlight_movie_id,]);

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;

    let alteredLastPage = last_page;

    if (location.pathname === routes.justAdded) {
      if (max_just_added_page_count && max_just_added_page_count < alteredLastPage) {
        alteredLastPage = max_just_added_page_count;
      }
    }

    return (
      <PageIndicator
        page={page}
        pageCount={alteredLastPage}
        onPageChange={onPageChange}
        scrollIntoView=".ShowMeSortByNavigation"
      />
    );
  };

  const numOfVideos = (param) => {
    const half = Math.ceil(videos.length / 2);
    const firstHalf = videos.slice(0, half);
    const secondHalf = videos.slice(half);

    if (param) {
      return firstHalf;
    } else {
      return secondHalf;
    }
  };

  const renderView = () => {
    let view = null;

    const NStitle = () => {
      let text = clearSpecialCharactersReplacements(location.pathname);

      if (location.pathname === `${routes.mostWatched}`) {
        text = `top gay porn ${type}`;
      }
      return text;
    };

    if (isWeb()) {
      view = (
        <>
          <MoviesScenesGrid
            data={numOfVideos(1)} // first half
            loading={loading}
            type={type}
            sort={sort}
            onShowMeClick={onTypeChange}
            onSortByClick={onSortByClick}
            error={error}
            title={NStitle()}
            subTitle={
              type === navigationOptions.scenes
                ? `${clearSpecialCharactersReplacements(location.pathname)} scenes`
                : `${clearSpecialCharactersReplacements(location.pathname)} movies`
            }
          />

          {!error && isWeb() && spotlight && Object.keys(spotlight).length !== 0 && (
            <BoxCoverPlayerDetailsComponent movieId={spotlight.movieId} sceneId={spotlight.sceneId} />
          )}

          <MoviesScenesGrid
            data={numOfVideos()} // second half
            loading={loading}
            type={type}
            sort={sort}
            onShowMeClick={onTypeChange}
            onSortByClick={onSortByClick}
            error={error}
            hideNavigation
          />
        </>
      );
    } else {
      view = (
        <MoviesScenesGrid
          data={videos}
          loading={loading}
          type={type}
          sort={sort}
          onShowMeClick={onTypeChange}
          onSortByClick={onSortByClick}
          error={error}
          title={isWeb() ? clearSpecialCharactersReplacements(location.pathname) : ''}
          subTitle={NStitle()}
        />
      );
    }

    return (
      <>
        {view}
        {renderPagination()}
      </>
    );
  };

  return <div className="JustAddedPage">{renderView()}</div>;
};

JustAddedPage.displayName = 'JustAddedPage';

export default JustAddedPage;
