import { ModalController } from '../../../controllers/modal-controller/modal.controller';
import DealsImport from './DealsImport.js';
import { showMemberDeals } from '../../../services/navigation/navigation.service';

export const displayBenefitsModalDialog = () => {
  const modal = (
    <div className="MemberDealsBgOverlay">
      <div className="MemberDealsModal">
        <button className="MemberDealsModalCLoseBtn" onClick={() => ModalController.removeModal()}>
          <i className="far fa-times-circle"></i>
        </button>

        <div className="DealsModalAccount" onClick={() => ModalController.removeModal()}>
          No Thanks. Take Me to My Nakedsword!
        </div>
        <div className="dealsModalHeader">SPECIAL OFFERS EXCLUSIVE TO OUR MEMBERS</div>

        <DealsImport numberOfDisplayedOffers={6} />

        <button
          className="MemberDealsModalSeeMoreBtn cursor-pointer"
          onClick={() => {
            showMemberDeals();
            ModalController.removeModal();
          }}
        >
          SEE MORE OFFERS
        </button>
      </div>
    </div>
  );
  ModalController.showModal(modal);
}; 