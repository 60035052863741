import { clearSpecialCharactersReplacements } from '../../../../services/util-service/util.service';
import { getTagDetails } from '../../../../services/tags-service/tags.service';
import useMoviesScenesData from '../../../../hook/useMoviesScenesData';

import PageIndicator from '../../../../components/PageIndicator/PageIndicator';
import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';
import BannerSetViewFunc from '../../../../components/BannerSetView/BannerSetViewFunc';
import SlickSlider from '../../../../components/SlickSlider/SlickSlider';
import BannerNatsPageMobileCookie from '../../../../components/BannerNatsPageMobileCookie/BannerNatsPageMobileCookie.js';

const ThemeDetailsPage = (props) => {
  const { name: propsThemeName, location = {} } = props;
  const {
    videos,
    pagination: { page, last_page },
    name = propsThemeName,
    banner = {},
    sort,
    type,
    loading,
    error,
    onPageChange,
    onTypeChange,
    onSortByClick,
  } = useMoviesScenesData({
    location,
    hookParams: { tags_name: propsThemeName },
    hookDetailsRequest: getTagDetails,
    hookDetailsParams: { route: propsThemeName },
  });

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;
    return (
      <PageIndicator
        page={page}
        pageCount={last_page}
        onPageChange={onPageChange}
        scrollIntoView=".ShowMeSortByNavigation"
      />
    );
  };

  return (
    <div className="ThemeDetailsPage">
      <BannerSetViewFunc bannerName="promo" />
      <SlickSlider slides={banner} />
      <BannerNatsPageMobileCookie />
      <MoviesScenesGrid
        data={videos}
        loading={loading}
        type={type}
        sort={sort}
        onShowMeClick={onTypeChange}
        onSortByClick={onSortByClick}
        error={error}
        title={clearSpecialCharactersReplacements(name)}
      />
      {renderPagination()}
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

ThemeDetailsPage.displayName = 'ThemeDetailsPage';

export default ThemeDetailsPage;
