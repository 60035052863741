import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import {
  getDirectorDetailsPageRoute,
  getMovieDetailsPageRoute,
  getStarDetailsRoute,
  getStudioDetailsRoute,
  getThemeDetailsRoute,
  getSexActDetailsRoute,
} from '../../services/navigation/navigation.service.routes';
import { searchHome } from '../../services/search-service/search.service';

import DropdownSelect from '../DropdownMenu/DropdownSelect';
import { useWLconfigContext } from '../../context/WLConfigContext';
import { isWeb } from '../../services/util-service/util.service';
import { ReactComponent as SearchIcon } from '../../images/NS_search_icon.svg';

import './MainSearch.scss';

const initialDropdownValue = { name: 'All', tag: 'all' };

export default function MainSearch(props) {
  const {
    toggleSearchVisible,
    desktopSearchVisible,
    handleSearchDropdownValue
  } = props;

  const { t } = useTranslation();
  const searchInputRef = useRef();
  const searchContainerRef = useRef();
  const { wl_config: { search_options = [] } = {} } = useWLconfigContext();
  // for <Dropdown />
  const showOnlyCheckedOptions = search_options?.filter((item) => item?.checked || item.tag === initialDropdownValue.tag) || [];
  const hasDropdownOptions = showOnlyCheckedOptions.length > 1; // the 'All' option excluded

  const abortController = new AbortController();

  const [state, setState] = useState({
    directors: [],
    movies: [],
    studios: [],
    stars: [],
    categories: [],
    sex_acts: [],
    searchValue: '',
    noResults: false,
    selectedDropdownValue: initialDropdownValue,
    loading: false
  });

  const {
    directors = [],
    movies = [],
    studios = [],
    stars = [],
    categories = [],
    sex_acts = [],
    searchValue,
    noResults,
    selectedDropdownValue,
    loading
  } = state;

  const hasResult = (
    directors = [],
    movies = [],
    stars = [],
    studios = [],
    categories = [],
    sex_acts = []
  ) => {
    if (
      directors.length !== 0 ||
      movies.length !== 0 ||
      studios.length !== 0 ||
      stars.length !== 0 ||
      categories.length !== 0 ||
      sex_acts.length !== 0
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const filter = selectedDropdownValue?.tag !== initialDropdownValue.tag ? selectedDropdownValue.tag : '';

    const delayDebounceTimeout = setTimeout(() => {
      if (searchValue) {
        setState(prevState => ({ ...prevState, loading: true }));

        searchHome(searchValue, filter, abortController)
          .then(resp => {
            const { directors, movies, stars, studios, categories, sex_acts } = resp?.data?.data;

            if (hasResult(directors, movies, stars, studios, categories, sex_acts)) {
              setState(prevState => ({
                ...prevState,
                directors,
                movies,
                stars,
                studios,
                categories,
                sex_acts,
                loading: false,
                noResults: false
              }));
            } else {
              setState(prevState => ({ ...prevState, noResults: true, loading: false }));
            }

            clearTimeout(delayDebounceTimeout);
          })
          .catch(err => console.log(err));
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceTimeout);
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [searchValue, selectedDropdownValue.tag]);

  useEffect(() => {
    const onClickOutsideHideSearch = (event) => {
      const elem = event.target;
      if (searchContainerRef.current) {
        if (isWeb()) {
          if (searchContainerRef.current?.contains(elem)) return;
          if (toggleSearchVisible) {
            toggleSearchVisible(false);
          }
        }
      }
      if (elem.classList.contains('SearchIcon')) {
        if (toggleSearchVisible) {
          toggleSearchVisible(!desktopSearchVisible);
        }
      }
    };

    document.addEventListener('click', onClickOutsideHideSearch);

    return () => {
      document.removeEventListener('click', onClickOutsideHideSearch);
    };
  }, [toggleSearchVisible, desktopSearchVisible]);

  const onInputChange = (event) => {
    const { value } = event.target;
    if (value.trim() === '') return;
    setState(prevState => ({ ...prevState, searchValue: value }));
  };

  const resetArrays = () => {
    return {
      directors: [],
      movies: [],
      studios: [],
      stars: [],
      categories: [],
      sex_acts: []
    }
  };

  const onChangeDropdownSection = (selected) => {
    setState(prevState => ({
      ...prevState,
      ...resetArrays(),
      selectedDropdownValue: selected,
      loading: false,
      noResults: false
    }));

    if (handleSearchDropdownValue) {
      handleSearchDropdownValue(selected.tag);
    }
    searchInputRef.current.focus();
  };

  const clearSearchValue = () => {
    resetToInitialState();
  };

  const resetToInitialState = (closeSearch) => {
    setState(prevState => ({
      ...prevState,
      ...resetArrays(),
      searchValue: '',
      loading: false,
      noResults: false
    }));

    if (closeSearch) {
      if (toggleSearchVisible) {
        toggleSearchVisible(false);
      }
      abortController.abort();
    }
  };

  const onInputKeyUp = (event) => {
    const { key } = event;
    if (key === 'Enter' && searchValue) {
      navigate(`/search?q=${searchValue}`);
      resetToInitialState(1);
    }
    if (key === 'Escape') {
      resetToInitialState(1);
    }
  };

  const renderSearch = () => {
    const renderSearchResultsLink = (data = [], getRouteFn, entity) => {
      if (data?.length === 0) return null;

      return (
        <div className="SearchResultsSection">
          <div className="SearchResultsSectionHeader">{t(`MainSearch.${entity}`)}</div>

          {data.map(item => {
            const { id, titleNs, title, name } = item;
            const text = titleNs || title || name;

            return (
              <Link
                className="SearchResultItem"
                to={getRouteFn(id, text)}
                replace={true}
                onClick={() => resetToInitialState(1)}
                key={`${id}-${name}`}
              >
                {text}
              </Link>
            )
          })}
        </div>
      );
    };

    const renderSearchResults = () => {
      if (loading) {
        return <div className="NoResults">Loading...</div>;
      }

      if (noResults) {
        return <div className="NoResults">{t('MainSearch.noResults')}</div>;
      }

      if (hasResult(directors, movies, stars, studios, categories, sex_acts)) {
        return (
          <div className="SearchResults">
            {renderSearchResultsLink(movies, getMovieDetailsPageRoute, 'movies')}
            {renderSearchResultsLink(studios, getStudioDetailsRoute, 'studios')}
            {renderSearchResultsLink(stars, getStarDetailsRoute, 'stars')}
            {renderSearchResultsLink(categories, getThemeDetailsRoute, 'categories')}
            {renderSearchResultsLink(sex_acts, getSexActDetailsRoute, 'sex_acts')}
            {renderSearchResultsLink(directors, getDirectorDetailsPageRoute, 'directors')}
          </div>
        );
      }
    };

    return (
      <div className="SearchInputContainer">
        <input
          name="q"
          type="text"
          ref={searchInputRef}
          value={searchValue}
          className={'searchTerm'}
          onChange={onInputChange}
          onKeyUp={onInputKeyUp}
          placeholder={`${hasDropdownOptions ? 'Search...' : t(`MainSearch.search`)}`}
        />
        <span className="SearchIcon">
          <SearchIcon />
        </span>
        {renderSearchResults()}
      </div>
    )
  };

  return (
    <div ref={searchContainerRef} className={`MainSearch ${desktopSearchVisible ? 'isOpen' : ''}`}>
      <div className={`SearchContainer ${hasDropdownOptions ? '' : 'hasDropdown'}`}>
        {hasDropdownOptions && (
          <DropdownSelect
            options={showOnlyCheckedOptions}
            onChangeSearchSection={val => onChangeDropdownSection(val)}
            clearSearchValue={clearSearchValue}
            selectedDropdownValue={selectedDropdownValue}
          />
        )}
        {renderSearch()}
      </div>
    </div>
  );
} 
