import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './MemberDealsPage.scss';

// stylized with validation - https://docs.google.com/spreadsheets/d/e/2PACX-1vTvQwC-_DYA8L0HyC9Op7n8uLU0OOdHaXy4Im8ZIN50tJWkdodQ-qQlj136qUaMJQ_bawwg1S9Jd0W2/pub?output=csv

//no formatting - https://docs.google.com/spreadsheets/d/e/2PACX-1vR8gksYDwcnjnNdXR8RbfvA_urMPf9Xr-4TEfyuzxnivvW_AanKZtRxEOxuPto0p85qWvIJ6iGsm4I_/pub?output=csv

// https://affiliates.nakedsword.com/public_html/ns_member_banners/MemberBenefitsOffers.csv

//https://docs.google.com/spreadsheets/d/e/2PACX-1vSoL9MC7jr9hfu7cCa_7PtmiCdBYFQ7xuygeAJWyIWQ5_TWgnjUgElaUmIEciqaguFEnE9YD0cX-zhn/pub?output=csv

// https://spotlight.nakedcdn.com/nakedsword/benefits-banners/MemberBenefitsOffersSMP2.csv

const DealsImport = ({ numberOfDisplayedOffers }) => {
  const [data, setData] = useState([]);
  const [shuffledData, setShuffledData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const cacheKey = 'memberBenefitsData';
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData) {
        setData(JSON.parse(cachedData));
      } else {
        try {
          const response = await fetch(
              'https://spotlight.nakedcdn.com/nakedsword/benefits-banners/MemberBenefitsOffers.csv'
          );

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const text = await response.text();

          // Papa.parse logic with debugging
          Papa.parse(text, {
            header: true,
            skipEmptyLines: true, // Skip empty rows
            complete: (results) => {
              // Debug parsed data
              console.log('Parsed Data:', results.data);
              sessionStorage.setItem(cacheKey, JSON.stringify(results.data));
              setData(results.data);
            },
            error: (error) => {
              console.error('Error parsing CSV:', error);
            },
          });
        } catch (error) {
          console.error('Error fetching or parsing CSV:', error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length) {
      setShuffledData([...data].sort(() => 0.5 - Math.random()));
    }
  }, [data]);

  const currentDate = new Date();
  const memberbenefitoffers = shuffledData.filter((offer) => {
    const startDate = new Date(offer.startdate);
    const endDate = new Date(offer.enddate);
    return currentDate >= startDate && currentDate <= endDate;
  });

  const displayedOffers = memberbenefitoffers.slice(0, numberOfDisplayedOffers);

  return (
    <div className="MemberDealsSection">
      {displayedOffers.map((offer) => (
        <a
          className="MemberDealsImport"
          href={offer.bannerlinkfromrevive}
          target="_blank"
          rel="noopener noreferrer"
          key={offer.memberbenefitoffer}
        >
          <img src={offer.bannerasset} alt={offer.title} />
        </a>
      ))}
    </div>
  );
};

export default DealsImport;